import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Acta Futura",
  "banner": "/images/core/pub_banner.jpg",
  "banner_caption": "Acta Futura"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Acta Futura is an electronic journal covering multidisciplinary research topics of potential interest in the long term for space science. The journal contains papers covering topics in which the space sector has a demonstrated interest. Thus, the content
includes a wide spectrum of scientific areas, ranging from fundamental physics to biomimetics, mission analysis, computational intelligence, neuroscience, artificial intelligence, energy systems and others.`}</p>
    <p><a parentName="p" {...{
        "href": "/gsp/ACT/resources/acta_futura_about"
      }}>{`More about Acta Futura`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      