import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'

import Page, { Section } from '../../shared/page-commons'
import SectionHeader from '../../shared/section-header'
import {
  Grid,
  Card,
  CardActionCall,
  CardTextGroup,
} from '../../shared/card-grid'
import { absoluteSize } from '../../shared/common-styles'
import Tag from '../../shared/tag'
import PublicationBanner from './_publication-banner'
import ActaFuturaBody from './_acta_futura.md'

const Ratio = styled.div`
  padding-bottom: 141%;
`

const Title = styled.h3`
  font-size: 1.125rem;
  margin-top: 10px;
  color: white;
`

const IssueLabel = styled(Tag)`
  max-width: calc(100% - 40px);
`

const Image = styled.img`
  ${absoluteSize};
  display: block;
  object-fit: cover;
`

const Gradient = styled(CardTextGroup)`
  ${absoluteSize};
  top: 130px;
  height: calc(100% - 130px);
  background-image: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.006) 9.1%,
    hsla(0, 0%, 0%, 0.022) 17.6%,
    hsla(0, 0%, 0%, 0.047) 25.7%,
    hsla(0, 0%, 0%, 0.081) 33.2%,
    hsla(0, 0%, 0%, 0.121) 40.4%,
    hsla(0, 0%, 0%, 0.166) 47.2%,
    hsla(0, 0%, 0%, 0.216) 53.6%,
    hsla(0, 0%, 0%, 0.268) 59.8%,
    hsla(0, 0%, 0%, 0.321) 65.8%,
    hsla(0, 0%, 0%, 0.375) 71.6%,
    hsla(0, 0%, 0%, 0.428) 77.3%,
    hsla(0, 0%, 0%, 0.478) 83%,
    hsla(0, 0%, 0%, 0.524) 88.6%,
    hsla(0, 0%, 0%, 0.565) 94.3%,
    hsla(0, 0%, 0%, 0.6) 100%
  );
`

const ImageTextGroup = styled(CardTextGroup)`
  ${absoluteSize};
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 35px;
  padding-top: 35px;
`

function IssueCard(props) {
  const { issue, issueNumber, ...restProps } = props

  return (
    <Card {...restProps}>
      <figure css="position: relative;">
        <Ratio />
        <Image src={withPrefix(issue.image_src)} alt={issue.title} />
        <Gradient />
        <ImageTextGroup>
          <IssueLabel>Issue {issueNumber}</IssueLabel>
          <div css="flex-grow: 1;" />
          <Title>{issue.title}</Title>
        </ImageTextGroup>
      </figure>
      <CardTextGroup>
        <CardActionCall>Read more</CardActionCall>
      </CardTextGroup>
    </Card>
  )
}

export default function ActaFuturaPage(props) {
  return (
    <Page bgColor="surface">
      <Helmet>
        <meta
          name="description"
          content="Acta Futura is an open access on-line journal run by scientists connected with the Advanced Concepts Team. It publishes papers on multidisciplinary research topics of potential interest in the long term for space science."
        />
        <meta
          property="og:description"
          content="Acta Futura is an open access on-line journal run by scientists connected with the Advanced Concepts Team. It publishes papers on multidisciplinary research topics of potential interest in the long term for space science."
        />
        <meta
          name="twitter:description"
          content="Acta Futura is an open access on-line journal run by scientists connected with the Advanced Concepts Team. It publishes papers on multidisciplinary research topics of potential interest in the long term for space science."
        />
      </Helmet>

      <PublicationBanner />
      <Section textColumn>
        <SectionHeader>Acta Futura</SectionHeader>
        <ActaFuturaBody />
      </Section>
      <Section bgColor="background">
        <Grid>
          {props.data.issues.nodes.map(({ frontmatter, fields, id }) => {
            return (
              <IssueCard
                issueNumber={frontmatter.issue_number}
                key={id}
                to={fields.slug}
                issue={frontmatter}
              />
            )
          })}
        </Grid>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query ActaFuturaPage {
    issues: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/collections/acta_futura/**" }
      }
      sort: { fields: frontmatter___issue_number, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          image_src
          title
          date
          issue_number
        }
        fields {
          slug
        }
      }
    }
  }
`
